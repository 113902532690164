import Api from '@/services/Index';

const getStudents = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/esl/attendances/student-informations/${id}`);
}

export default {
    getStudents,

}
